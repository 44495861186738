import React, { useEffect, useState } from 'react';
import { Box, Stack, Grid, Breadcrumbs, Button,useMediaQuery } from '@mui/material';
import ApiService from '../../utils/ApiService';
import AllGridView from '../KnowledgeManagement/AllGridView';
import MycontextChild from './MycontextChild';
import checkboxHelper from './checkboxHelper';
import { openDialog } from "../../features/dialogBox/dialogBoxSlice";
import { useSelector, useDispatch } from 'react-redux';
import PageName from '../../utils/getPageName';
import SharedDialog from '../../features/dialogBox/dialogBox';
import SharedDrawer from '../../features/drawers/Drawer';
import UploadDrawer from '../../components/drawerBody/Tstream/FileLibrary/StreamDrawer/UploadDrawer';
import DeleteDialog from '../KnowledgeManagement/DialogKM/DeleteDialog';
import RenameDialog from '../KnowledgeManagement/DialogKM/RenameDialog';
import { openDrawer } from "../../features/drawers/drawerSlice";
import TransitionsSnackbar from './Constantfile/Snackbar';
import DashboardHeading from './DashboardHeading';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DownloadBar from '../../features/downloadBar/DownloadBar';

const RecommendedVideo = ({ individualAPI = 'recommendedVideo', handleViewAllClick, sectionTitle}) => {
  ///////*****Video Menu******/
  const userDetail = useSelector((state) => state?.user?.userListName)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedItems, setSelectedItems] = useState(new Set([]));
  const [isFixedTop, setisFixedTop] = useState(false);
  console.log("a1",selectedItems)
  const [checkedData, setCheckedData] = useState();
  const [metaData, setMetaData] = useState();
  // const [dashboardList, setDashboardList] = useState([]);
  const [checkboxGridHelper, setCheckboxGridHelper] = useState(
    checkboxHelper()
  );
  const [uploadMode, setUploadMode] = useState('video') 
  console.log('a1',uploadMode)
  
  const [dynamicDeleteMessage, setDynamicDeleteMessage] = useState('');
  const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
  // console.log('a1',selectedId,setSelectedId)
  const dispatch = useDispatch();

  const openDialogPublish = (id) => {
    setAnchorEl(null);
    dispatch(openDialog(id));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  ///////*****End Video Menu******/

  ///////*****Api Recommended video******/
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [recommendedVideosMaster, setRecommendedVideosMaster] = useState([]);
  const [recommendedVideosCount, setRecommendedVideosCount] = useState(0);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
  const dataParams = {
    related: ['streamCategory','streamSubcategory','modifyBy']
}
useEffect(() => {
    ApiService.get(individualAPI, dataParams)
    // console.log('a2',individualAPI)
      .then((recommendedVideos) => {
        if (methodsAndState.editFlag?.value === "create") {
          setRecommendedVideosCount(recommendedVideos.data.data.length)
          setRecommendedVideos(recommendedVideos.data.data.slice(0, 4));
          setRecommendedVideosMaster(recommendedVideos.data.data);
        } else {
          // Assuming getRecommandedVideo is some function to process the data
          setRecommendedVideosCount(recommendedVideos.data.data.length)
          setRecommendedVideos(recommendedVideos.data.data.slice(0, 4));
          setRecommendedVideosMaster(recommendedVideos.data.data);
        }
        console.log("Fetched recommended videos:", recommendedVideos.data.data);
      })
      .catch((error) => {
        console.error("Error fetching recommended videos:", error);
      });
}, []); 
  
  const [editFlag, setEditFlag] = useState({ flag: false, value: "" })
  const [selectedList, setSelectedList] = useState([]);
  const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
  const [lastModified, setLastModified] = React.useState('');

  const handleModifiedChange = (event) => {
    setLastModified(event.target.value);
  };
  const openDrawerPublish = (id) => {
    console.log("Naam",{id,individualAPI})
    if (id == "editRecommandedFile") {
      setEditFlag({ flag: false, value: "edit" })
    } else {
      setEditFlag({ flag: true, value: "create" })
    }
    setAnchorEl(null);
    dispatch(openDrawer("editRecommandedFile" + individualAPI));
  }

  const [hardRefresh, setHardRefresh] = useState(true);
const editNewVideo = (video, isRecommanded) => {
    let tempDashboardList = [...recommendedVideos];
    const index = tempDashboardList.findIndex(item => item.id === video.id);
    console.log("t1",index)
    if (index !== -1) { 
        const updatedVideo = {
            ...video,
            isUpdated: true 
        };
        tempDashboardList.splice(index, 1, updatedVideo); 
        setRecommendedVideos(tempDashboardList);
        console.log("Roar", { tempDashboardList });
        if (isRecommanded) {
            setHardRefresh(false);
        }
        console.log({ updatedVideo, isRecommanded });
    } else {
        console.error("Video not found in dashboardList");
    }
}

 // ----------------------------- UPDATE META DATA ------------
 const updateMetaData = (count) => {
  setRecommendedVideosCount(recommendedVideosCount + count);
}

  const methodsAndState = {
    dashboardList: recommendedVideos,
    // checkboxGridHelper,
    handleModifiedChange,
    isRecommanded: true,
    setSelectedItems,
    selectedItems,
    openDialogPublish,
    setEditFlag,
    editNewVideo,
    setDashboardList: setRecommendedVideos,
    editFlag,
    // setDashboardList,
    // setSelectedItems,
    // selectedItems,
    setSelectedId,
    selectedId,
    setSelectedList,
    selectedList,
    setDynamicDeleteMessage,
    dynamicDeleteMessage,
    updateMetaData,
    setMetaData, metaData,
    setSnackBar,
    snackBar,
    // onChangeSelectCategory,
    // selectRef,
    setCheckedData,
    checkedData,
    headerCheckStatus,
    checkboxGridHelper,
    setHeaderCheckStatus,
    page: sectionTitle == 'Videos ' ? PageName.AllVideos : PageName.AllAudios,
    openDrawerPublish,
    individualAPI,
    uploadMode: individualAPI == 'audioPlaylist' ? 'audio' : 'video',
    individualAPIRename: individualAPI == 'audioPlaylist' ? 'renameAudio' : 'renameVideo' ,
  }
  const isMediumOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <MycontextChild.Provider value={methodsAndState}>
       <Stack sx={{ backgroundColor: '#ffffff' }}>
          <Grid container spacing={2} sx={{ mt: 0, }}>
              <Grid item xs={8} sm={8} sx={{ height: { md: '4rem', lg: "4rem", xl: "4rem" },display:'flex',alignItems:'center' }}>
                  <Breadcrumbs aria-label="breadcrumb">
                          <Box
                              sx={{ zIndex: '99999' }}>
                              <DashboardHeading
                                  title={sectionTitle}
                                  numItems={recommendedVideosCount}
                              />
                          </Box>
                  </Breadcrumbs>
              </Grid>
              <Grid item xs={4} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box sx={{ marginTop: isFixedTop ? '0rem' : '' }}>
                  {isMediumOrLarger ? (
                      <Button endIcon={<ArrowOutwardIcon />} size="large" onClick={handleViewAllClick}  sx={{ textTransform:'none !important',color:'#212121 !important'}}>
                          View all
                      </Button>):(
                         <Button
                         id='folderFileOption'
                         variant="text"
                         size="large"
                         onClick={handleViewAllClick}  
                         sx={{ textTransform:'none !important',color:'#212121 !important'}}
                         >
                         <ArrowOutwardIcon />
                     </Button>
                      )}
                  </Box>
              </Grid>
          </Grid>
      </Stack>
      <Box>
        <Box>
          <AllGridView checkboxRequied={false} page={sectionTitle == 'Videos' ? PageName.AllVideos : PageName.AllAudios} context={MycontextChild} />
        </Box>
      </Box>
      <SharedDrawer id={"editRecommandedFile" + individualAPI}>
        <UploadDrawer currentId={"editRecommandedFile" + individualAPI} context={MycontextChild} />
      </SharedDrawer>
      <SharedDialog id={"renameRecommandedDialog" + individualAPI}>
        <RenameDialog currentId={"renameRecommandedDialog" + individualAPI} context={MycontextChild} />
      </SharedDialog>
      <SharedDialog id={"deleteRecommandedDialog" + individualAPI}>
        <DeleteDialog currentId={"deleteRecommandedDialog" + individualAPI} context={MycontextChild} />
      </SharedDialog>
      <TransitionsSnackbar context={MycontextChild} />
      <DownloadBar context={MycontextChild} />
    </MycontextChild.Provider>
  )
}

export default RecommendedVideo