import axios from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
// const BASE_URL = 'https://gatewayapi.eela.tech/api';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      comapnyLog: "api/v1/company/1",
      // folderpermission: "api/v1/folderpermission",
      // tags: "api/v1/tags",
      renamefileManager: "api/v1/rename/file-manager",
      filemanagerActivity: "api/v1/video-activity",
      categories: "api/v1/categories",
      // category: "api/v1/category",
      tags: "api/v1/user-tag",
      search: "api/v1/file-manager-search",
      tagCloud: "api/v1/tags-cloud",
      fileManager: "api/v1/file-manager",
      permission: "api/v2/user-permissions",
      fileDetail:"api/v1/file-details",
      // folderDelete: "foldersoftdelete",

      ///////----------Start Stream-------------------////////////////
      category: "api/v1/stream-video-category",
      recentTags:"api/v1/stream-tag",
      folderpermission: "api/v1/video-service",
      recommendedVideo:"api/v1/recommended-video",
      createPlaylist:"api/v1/playlist",
      particularPlaylist:"api/v1/video-service",
      particularPlaylistUpdate: "api/v1/video-update",
      particularAudioPlaylist:"api/v1/stream-audio",
      particularAudioPlaylistUpdate: "api/v1/stream-audio",
      allPlaylistName : 'api/v1/playlist',
      allCategoriesName: 'api/v1/stream-category',
      allSubcategoriesName: 'api/v1/stream-subcategory',
      categoryToSubcategory : 'api/v1/stream-video-category',
      streamTags: 'api/v1/stream-tag',
      renameVideo:'api/v1/video-update',
      renameAudio:'api/v1/stream-audio',
      // audioDelete:'api/v1/stream-audio-softdelete',

       ///////----------End Stream-------------------////////////////
      // -------------

      fileFolderSoftDelete: "api/v1/video-delete",
      fileFolderRestore: "api/v1/video-trash",

      // ---------- trash page apis end point ------------

      fileArchive: "api/v1/video-trash-data",
      fileRestore: "api/v1/file-manager/restore",
      folderRestore: "api/v1/folderrestore",
      deleteTrash: "api/v1/empty-trash-data",
      permanentDelete: 'api/v1/file-manager/force-delete/',
      multiplefilefolderdelete: 'api/v1/video-trash-delete',
      multiplefiledelete: 'api/v1/multiple-file-delete',
      multiplefolderdelete: 'api/v1/multiple-folder-delete',

      // Audio Category ///

      audioPlaylist:"api/v1/stream-audio",
      audioDelete:'api/v1/stream-audio-softdelete',

      // ----------- Filter Category -------
      // filterCategory: "v1/filter",
      // userInfo: "v1/userinfo",
      getpinned: "api/v1/pinned-video",
      filefolderPin: 'api/v1/pinned-video',
      unpinned: 'api/v1/unpinned-video',
      tagSearch: "api/v1/search-tag",
      filterCategory: "api/v1/filter",
      userInfo: "api/v1/userinfo",

      // --------------Move File----------------
      moveFile: 'api/v1/move/file-manager',

      // ------------ Playlist APIs --------------
      playlist: 'api/v1/playlist',
      playListSoftDelete: 'api/v1/playlist-softdelete',
      videoSearch:'api/v1/global-search'

    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    const responseType = endpointKey === 'fileManager' ? 'blob' : 'json'; // Set the responseType based on endpointKey
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() },
        responseType
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', { error });
    }
  }

  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? 'API request failed:', { error });
    }
  }

  async postAdopter(endpointKey, data = null, searchParam = null, userId = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      endpoint = endpoint + '?app=' + getAppToken();
      if(userId){
        endpoint = endpoint + '&userId=' + userId;
      }
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      console.log("Discussion", {error})
      throw new Error(error?.response?.data?.errors?.[0]?.title ?? error?.response?.data?.errors?.title ?? 'API request failed:');
    }
  }

  // async postCheckCond(endpointKey, data = null, searchParam = null, userId = null) {
  //   try {
  //     //console.debug(data)
  //     let endpoint = this.endpoints[endpointKey];
  //     if (searchParam) {
  //       endpoint = endpoint + '/' + searchParam;
  //     }
  //     endpoint = endpoint + '?app=' + getAppToken();
  //     if(userId){
  //       endpoint = endpoint + '&userId=' + userId;
  //     }
  //     const response = await this.api.post(endpoint, data, {
  //       headers: { Authorization: 'Bearer ' + getSelfToken() }
  //     });
  //     return response;
  //   } catch (error) {
  //     console.log("Discussion", {error})
  //     throw new Error(error);
  //   }
  // }

}



export default new APIService(BASE_URL);
